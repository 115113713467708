import { Box, Divider, Flex, Image, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { Panel } from "areas/cart/components"
import { IS_NA, IS_JEWLR, jewlr_t } from "helpers/application"
import { to_currency } from "helpers/number"

import CheckoutButton from "./checkout-button"

const TitleContainer = styled(Flex)`
  align-items: center;
  margin-bottom: 10px;
  &:before,
  &:after {
    border-bottom: 1px solid ${(props) => props.theme.colors.grey[20]};
    content: "";
    flex: 1;
    ${(props) => props.theme.mediaQueries.tablet`
      content: none;
    `}
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`

const Title = styled(Typography).attrs({
  as: "h3",
})`
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
`

const Summary = styled(Flex).attrs((props) => ({
  justifyContent: "space-between",
  ...props,
}))``

const SummaryText = styled(Typography).attrs((props) => ({
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "21px",
  ...props,
}))``

const InternalOrderSummary = ({ refs, summary }) => (
  <Panel pb={{ _: "10px", tablet: 3 }}>
    <Box ref={refs.cartSummaryRef}>
      <TitleContainer justifyContent="space-between">
        <Flex alignItems="center" gap={1}>
          <Image
            alt={`${jewlr_t("Cart")} Summary`}
            height={{ _: "18px", tablet: "22px" }}
            src={require("images/icons/cart_black.svg")}
            width="24px"
          />
          <Title>{jewlr_t("Cart")} Summary</Title>
        </Flex>
      </TitleContainer>
      <Divider
        color="grey.20"
        display={{ _: "none", tablet: "block" }}
        height="0.5px"
        my="10px"
      />
      <Summary py={1}>
        <SummaryText>Item Subtotal</SummaryText>
        <SummaryText>{summary.order_total}</SummaryText>
      </Summary>
      {summary.promo_code.promo_code_applied && (
        <Summary>
          <SummaryText color="greenDarker">
            Promo Code: {summary.promo_code.promo_code_applied}
          </SummaryText>
          <SummaryText color="greenDarker" data-cy="cart-promo-code-price">
            {to_currency(summary.promo_code.promo_code_discount_amount, {
              precision: 2,
            })}
          </SummaryText>
        </Summary>
      )}
      {summary.bmsm_discount !== 0 && (
        <>
          <Summary pt={1}>
            <SummaryText color="greenDarker">
              Buy More Save More Discount
            </SummaryText>
            <SummaryText color="greenDarker">
              {to_currency(summary.bmsm_discount, { precision: 2 })}
            </SummaryText>
          </Summary>
          <Typography color="grey.65" fontSize="12px" lineHeight="12px">
            {summary.bmsm_percentage}% off items {summary.bmsm_message}
          </Typography>
        </>
      )}
      {summary.promotion_discount !== 0 && (
        <>
          <Summary pt={1}>
            <SummaryText color="greenDarker">
              {summary.promotion_description}
            </SummaryText>
            <SummaryText color="greenDarker">
              {to_currency(summary.promotion_discount, {
                precision: 2,
              })}
            </SummaryText>
          </Summary>
          <Typography color="grey.65" fontSize="12px" lineHeight="12px">
            {summary.promotion_percentage}% off items{" "}
            {summary.promotion_message}
          </Typography>
        </>
      )}
      {IS_NA() && (
        <Summary pt={1}>
          <SummaryText color="grey.62">Sales Tax</SummaryText>
          <SummaryText color="grey.62" fontWeight={500}>
            Calculated at checkout
          </SummaryText>
        </Summary>
      )}
      <Divider color="grey.20" height="0.5px" my="10px" />
      <Summary pb="18px" pt={1}>
        <SummaryText>Estimated Total</SummaryText>
        <SummaryText>
          {summary.total} {summary.currency_code}
        </SummaryText>
      </Summary>
    </Box>
    <CheckoutButton refs={refs} />
    {IS_JEWLR() && (
      <Typography
        color="#757575"
        fontSize={{ _: "11px", tablet: "8px" }}
        fontWeight={{ _: 500, tablet: 400 }}
        mt={3}
        textAlign="center"
      >
        JEWLR USES TRANSPORT LAYER SECURITY (TLS) ENCRYPTION.
      </Typography>
    )}
  </Panel>
)

InternalOrderSummary.propTypes = {
  refs: PropTypes.object,
  summary: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    summary: state.cart.summary,
  }
}

const OrderSummary = connect(mapStateToProps)(InternalOrderSummary)

export default OrderSummary
