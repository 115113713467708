import { faWindowClose } from "@jewlr/pro-regular-svg-icons/faWindowClose"
import { Box, Flex, FontAwesomeIcon, Typography } from "@jewlr/storybook/core"
import React from "react"
import styled from "styled-components"

import { jewlr_t } from "helpers/application"

const Container = styled(Box).attrs({
  width: { _: "100%", tablet: "auto" },
})`
  background: #fff1cc;
  border: 1px solid #e6ce8f;
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 10px;
  padding: 16px 20px;
  ${(props) => props.theme.mediaQueries.tablet`
    border: none;
    margin-bottom: -8px;
    margin-top: 24px;
  `}
`

const OutOfStockBanner = () => (
  <Container>
    <Flex alignItems="center" mb="2px">
      <FontAwesomeIcon icon={faWindowClose} mr={1} />
      <Typography
        as="h2"
        fontSize="18px"
        fontWeight="700"
        letterSpacing="-0.03em"
      >
        OUT OF STOCK
      </Typography>
    </Flex>
    <Typography fontSize="14px" letterSpacing="-0.03em" ml={3}>
      One or more of the items in {jewlr_t("cart")} is no longer available.
    </Typography>
  </Container>
)

export default OutOfStockBanner
