import { faHand } from "@jewlr/pro-light-svg-icons/faHand"
import { faChevronDown } from "@jewlr/pro-regular-svg-icons/faChevronDown"
import { faChevronUp } from "@jewlr/pro-regular-svg-icons/faChevronUp"
import { faCheckCircle as solidCheckCircle } from "@jewlr/pro-solid-svg-icons/faCheckCircle"
import { Expand } from "@jewlr/storybook/animate"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  Image,
  MediaBox,
  Typography,
} from "@jewlr/storybook/core"
import { FocusScope } from "@react-aria/focus"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import {
  AddonItem,
  AddonSlider,
  TitleDivider,
  TitleDividerText,
} from "areas/cart/components"
import { addAddon } from "areas/cart/store/actions"
import { freeGiftPromoEnabled } from "helpers/holiday"
import { to_currency } from "helpers/number"
import { useNavigation } from "helpers/use-navigation"

const Container = styled(Box)`
  background: white;
  border: 1px solid ${(props) => props.theme.colors.grey[20]};
  border-radius: 12px;
  box-shadow: 0px 0px 12px 0px #f2f2f2;
  margin-top: 24px;
  padding: 18px 12px;
  text-align: center;
  ${(props) =>
    props.promo &&
    `
    margin-top: 16px;
  `}
`
const AddonButton = styled(Button).attrs((props) => ({
  variant: "text",
  ...props,
}))`
  border: 2px solid
    ${(props) => (props.selected ? props.theme.colors.selected : "transparent")};
  border-radius: 5px;
  position: relative;
  width: 100%;
  &:focus-visible {
    border-color: ${(props) => props.theme.colors.selected};
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`
const AddonImage = styled(Image)`
  border-radius: 5px;
  width: 130px;
`
const AddonIcon = styled.div`
  background-color: white;
  border-radius: 22px;
  bottom: -10px;
  color: ${(props) =>
    props.selected ? props.theme.colors.selected : props.theme.colors.grey65};
  font-size: 22px;
  height: 22px;
  left: 50%;
  line-height: 1;
  position: absolute;
  transform: translateX(-50%);
  width: 22px;
`
const AddonName = styled(Typography).attrs({
  as: "h4",
  fontSize: "16px",
  fontWeight: "500",
  letterSpacing: "-0.02em",
  lineHeight: "18px",
  mb: 1,
})``
const DetailsBtn = styled(Button).attrs({
  color: "primary",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "22px",
  variant: "text",
})``

const FreeGiftItem = ({ gift, handleClick, selected, ...props }) => {
  const keyboardProps = useNavigation({ direction: "horizontal" })

  return (
    <AddonButton
      aria-checked={selected}
      onClick={handleClick}
      role="radio"
      selected={selected}
      {...keyboardProps}
      {...props}
    >
      <AddonImage alt={gift.title} ratio="95 / 95" src={gift.image_url} />
      {selected && (
        <AddonIcon selected>
          <FontAwesomeIcon icon={solidCheckCircle} />
        </AddonIcon>
      )}
    </AddonButton>
  )
}

FreeGiftItem.propTypes = {
  gift: PropTypes.object,
  handleClick: PropTypes.func,
  selected: PropTypes.bool,
}

const NoGiftItem = ({ handleClick, selected, ...props }) => {
  const keyboardProps = useNavigation({ direction: "horizontal" })

  return (
    <AddonButton
      aria-checked={selected}
      bg="#f8f8f8"
      onClick={handleClick}
      role="radio"
      selected={selected}
      {...keyboardProps}
      {...props}
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        height="130px"
        justifyContent="center"
        width="130px"
      >
        <FontAwesomeIcon
          color="grey.30"
          fontSize="20px"
          icon={faHand}
          mb="6px"
        />
        <Typography fontSize="14px" letterSpacing="-0.02em" lineHeight="18px">
          No Thanks
        </Typography>
      </Flex>
      {selected && (
        <AddonIcon selected>
          <FontAwesomeIcon icon={solidCheckCircle} />
        </AddonIcon>
      )}
    </AddonButton>
  )
}

NoGiftItem.propTypes = {
  handleClick: PropTypes.func,
  selected: PropTypes.bool,
}

const InternalFreeGifts = ({ chooseGift, freeGifts, selectedFreeGift }) => {
  if (!freeGifts?.length) return null

  const [selectedGift, setGift] = useState(selectedFreeGift || freeGifts[0])
  const [showGiftDetails, toggleGiftDetails] = useState(false)

  return (
    <MediaBox lessThan="tablet">
      {!freeGiftPromoEnabled() && (
        <TitleDivider>
          <TitleDividerText>Choose A Free Gift</TitleDividerText>
        </TitleDivider>
      )}
      <Container promo={freeGiftPromoEnabled()}>
        <FocusScope>
          <AddonSlider
            aria-label="Choose a free gift"
            data-cy="cart-freegift-category-mobile"
            justifyContent="center"
            role="radiogroup"
          >
            {freeGifts.map((gift) => {
              const selected =
                (selectedFreeGift?.addon_code ||
                  selectedFreeGift?.gift_code) === gift.gift_code
              return (
                <AddonItem
                  data-cy={`cart-freegift-mobile-${gift.gift_code}`}
                  key={`freegift-${gift.gift_code}`}
                  mb="18px"
                >
                  <FreeGiftItem
                    gift={gift}
                    handleClick={() => {
                      setGift(gift)
                      chooseGift(gift.gift_code)
                    }}
                    selected={selected}
                    tabIndex={selected ? 0 : -1}
                  />
                </AddonItem>
              )
            })}
          </AddonSlider>
        </FocusScope>
        <AddonName data-cy="cart-freegift-title-mobile">
          {freeGiftPromoEnabled()
            ? "Choose your watch style"
            : selectedGift.title}
        </AddonName>
        <Flex alignItems="center" gap="6px" justifyContent="center">
          <Typography
            as="h5"
            color="greenDarker"
            fontSize="30px"
            fontWeight="500"
          >
            FREE
          </Typography>
          <Box
            color="grey.62"
            fontSize="12px"
            fontWeight="500"
            letterSpacing="1px"
            lineHeight="12px"
            textAlign="left"
          >
            {freeGiftPromoEnabled() ? (
              <>
                <Typography>RETAIL UP TO</Typography>
                <Typography>
                  {to_currency(sl.config.free_gift_promo?.retail_value || 0)} VALUE
                </Typography>
              </>
            ) : (
              <>
                <span>RETAIL</span>
                <Box as="strike" display="block">
                  {to_currency(selectedGift.retail_price)}
                </Box>
              </>
            )}
          </Box>
        </Flex>
        <DetailsBtn
          aria-controls="cart-free-gift-mobile-details"
          aria-expanded={showGiftDetails}
          mt={1}
          onClick={() => toggleGiftDetails(!showGiftDetails)}
        >
          View Item Details
          <FontAwesomeIcon
            color="primary"
            fontSize="8px"
            icon={showGiftDetails ? faChevronUp : faChevronDown}
            ml="4px"
          />
        </DetailsBtn>
        <Expand
          id="cart-free-gift-mobile-details"
          mt={1}
          show={showGiftDetails}
        >
          <Typography
            fontSize="12px"
            fontWeight="500"
            lineHeight="14px"
            textAlign="left"
          >
            {selectedGift.description}
          </Typography>
          {freeGiftPromoEnabled() && (
            <>
              <br />
              <Typography
                fontSize="10px"
                italic
                lineHeight="12px"
                textAlign="left"
              >
                While supplies last.
              </Typography>
            </>
          )}
        </Expand>
      </Container>
    </MediaBox>
  )
}

InternalFreeGifts.propTypes = {
  chooseGift: PropTypes.func,
  freeGifts: PropTypes.array,
  selectedFreeGift: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    freeGifts: state.cart.free_gift_offers,
    selectedFreeGift: state.cart.addons.find(
      (addon) => addon.addon_type == "GIFT"
    ),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    chooseGift: (addonCode) => {
      // Mobile Cart select gift
      dispatch(addAddon(addonCode, "GIFT", "cart_mobile"))
    },
    dispatch,
  }
}

const MobileFreeGifts = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalFreeGifts)

export default MobileFreeGifts
