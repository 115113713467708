import { faMinus } from "@jewlr/pro-regular-svg-icons/faMinus"
import { faPlus } from "@jewlr/pro-regular-svg-icons/faPlus"
import { faSpinner } from "@jewlr/pro-regular-svg-icons/faSpinner"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  Image,
  Typography,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { addAddon, removeAddon } from "areas/cart/store/actions"

import { AddonItem, QtyIcon } from "../components"

import AddonPrice from "./addon-price"

const ExclusiveBox = styled(Box)`
  background: ${(props) => props.theme.colors.white};
  border: 0.5px solid ${(props) => props.theme.colors.grey[20]};
  border-radius: 6px 6px 0 0;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 3px;
`

const AddonButton = styled(Button).attrs({
  variant: "text",
})`
  border-radius: 6px;
  display: block;
  &:focus {
    outline-offset: -2px;
  }
`

const CTABox = styled(Flex)`
  align-items: center;
  background: ${(props) => props.theme.colors.blackPrint};
  border-radius: 0 0 6px 6px;
  color: ${(props) => props.theme.colors.white};
  gap: 8px;
  justify-content: center;
  padding-bottom: 8px;
  padding-top: 8px;
  width: 100%:
`

const QtyValue = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 14px;
`

const InternalAddonMobile = ({
  addon,
  addExclusiveAddon,
  disableActions,
  removeExclusiveAddon,
  selectedAddons,
  setAddonInModal,
  toggleAddonModal,
}) => {
  const adding =
    disableActions.id === addon.code && disableActions.action === "adding"
  const removing =
    disableActions.id ===
      (selectedAddons[addon.code] && selectedAddons[addon.code].id) &&
    disableActions.action === "removing"

  const addonContent = (
    <>
      <ExclusiveBox>
        <Image
          alt={addon.title}
          mb="3px"
          ratio="1 / 1"
          src={addon.images[0]}
          width="138px"
        />
        <AddonPrice price={addon.price} retail_price={addon.retail_price} />
      </ExclusiveBox>
      <CTABox>
        {!selectedAddons[addon.code] && !adding ? (
          <Typography fontSize="16px" fontWeight="500" lineHeight="16px">
            + Add
          </Typography>
        ) : (
          <>
            <QtyIcon
              color="white"
              data-cy="cart-addon-minus-btn-mobile"
              disabled={disableActions.id !== ""}
              fontSize="14px"
              onClick={() => {
                if (disableActions.id === "") {
                  removeExclusiveAddon(selectedAddons[addon.code].id)
                }
              }}
            >
              <FontAwesomeIcon
                icon={removing ? faSpinner : faMinus}
                spin={removing}
              />
            </QtyIcon>
            <QtyValue data-cy="cart-addon-qty-mobile">
              {selectedAddons[addon.code]
                ? selectedAddons[addon.code].quantity
                : 0}
            </QtyValue>
            <QtyIcon
              color="white"
              data-cy="cart-addon-plus-btn-mobile"
              disabled={disableActions.id !== ""}
              fontSize="14px"
              onClick={() => {
                if (disableActions.id === "") {
                  addExclusiveAddon(addon.code, addon.type)
                }
              }}
            >
              <FontAwesomeIcon
                icon={adding ? faSpinner : faPlus}
                spin={adding}
              />
            </QtyIcon>
          </>
        )}
      </CTABox>
    </>
  )

  return (
    <AddonItem flex="0 0 50%" maxWidth="140px" mb={1} mt="20px">
      {!selectedAddons[addon.code] && !adding && !removing ? (
        <AddonButton
          data-cy="cart-addon-mobile"
          disabled={adding || removing}
          onClick={() => {
            setAddonInModal(addon)
            toggleAddonModal(true)
          }}
        >
          {addonContent}
        </AddonButton>
      ) : (
        addonContent
      )}
    </AddonItem>
  )
}

/**
 * Get "BUNDLE" addons with quantity
 *
 * @return {obj} - {GIFTCRSN: {quantity: 2, ...<other addon proeprties>}}
 */
const getSelectedAddons = (addons) => {
  return (addons || [])
    .filter((a) => a.addon_type === "BUNDLE" || a.addon_type === "GIFT_CARD")
    .reduce(
      (acum, addon) =>
        Object.assign(acum, {
          [addon.addon_code]: {
            quantity: acum[addon.addon_code]
              ? acum[addon.addon_code].quantity + 1
              : 1,
            ...addon,
          },
        }),
      {}
    )
}

const mapStateToProps = (state) => {
  return {
    selectedAddons: getSelectedAddons(state.cart.addons),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addExclusiveAddon: (addonCode, addon_type) => {
      // addAddon from cart_mobile
      dispatch(
        addAddon(
          addonCode,
          addon_type,
          "cart_mobile",
          ownProps.toggleDisableActions
        )
      )
    },
    removeExclusiveAddon: (addonId) => {
      dispatch(removeAddon(addonId, ownProps.toggleDisableActions))
    },
  }
}

InternalAddonMobile.propTypes = {
  addExclusiveAddon: PropTypes.func,
  addon: PropTypes.object,
  disableActions: PropTypes.object,
  removeExclusiveAddon: PropTypes.func,
  selectedAddons: PropTypes.object,
  setAddonInModal: PropTypes.func,
  toggleAddonModal: PropTypes.func,
  toggleDisableActions: PropTypes.func,
}

const AddonMobile = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalAddonMobile)

export default AddonMobile
